import React, { useContext } from 'react'
import ConnectButton from './WalletConnectButton.js'
import Logo from './../../assets/utility/logo.png'
import { MainContext } from '../../App.js'
export default function Navbar() {
    const { project } = useContext(MainContext)
    if (!project) return (<></>)
    else {
        return (
            <div className='navbar'>
                <div className='navbar__logo-container'>
                    <img className='navbar__logo' src={project.logo || Logo} alt='logo' />
                </div>
                <ConnectButton />
            </div>
        )
    }
}
