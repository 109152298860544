import config from "../config"

export const updateProject = async (projectId, setProject) => {
    try {

        const res = await fetch(`${config.apiURL}/get-project`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId || 'solanacryptic'
            })
        })
        const resJson = await res.json()
        await setProject(resJson.data.project)

    } catch (err) {
        console.log(err)
        setProject({})
    }
}

export const updateNFTsOwned = async (publicKey, projectId, setNFTs) => {
    try {
        const res = await fetch(`${config.apiURL}/get-nfts`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId || 'solanacryptic',
                publicKey: publicKey
            })
        })
        const resJson = await res.json()
        await setNFTs(resJson.data.NFTs)

        // setNFTs([
        //     {
        //         projectId: 'solanacryptic',
        //         collectionId: 'rusted-satchel',
        //         mintAddress: '2jisjij',
        //         openingStatus: {
        //             lastOpened: {
        //                 openedAt: Date.now() - 10 * 24 * 60 * 60 * 1000,
        //                 openedBy: '7my'
        //             },
        //             nextOpeningTime: Date.now() + 10 * 24 * 60 * 60 * 1000,
        //         },
        //         name: 'Rusted Satchel',
        //         royalty: { paid: true },
        //         image: 'https://media.discordapp.net/attachments/881015143369175051/1084398319767400478/360_F_554536963_T4CwE07lbWJJr8dqRcHa8qC4wWfOHB6O-removebg-preview.png?width=346&height=346',
        //         data: {}
        //     },

        //     {
        //         projectId: 'solanacryptic',
        //         collectionId: 'solana-chest',
        //         mintAddress: '2jisjij',
        //         openingStatus: {
        //             lastOpened: {
        //                 openedAt: Date.now() - 10 * 24 * 60 * 60 * 1000,
        //                 openedBy: '7my'
        //             },
        //             nextOpeningTime: Date.now() + 10 * 24 * 60 * 60 * 1000,
        //         },
        //         name: 'Rusted Satchel',
        //         royalty: { paid: true },
        //         image: 'https://media.discordapp.net/attachments/881015143369175051/1084398319767400478/360_F_554536963_T4CwE07lbWJJr8dqRcHa8qC4wWfOHB6O-removebg-preview.png?width=346&height=346',
        //         data: {}
        //     }

        // ])
    } catch (err) {
        console.log(err)
        setNFTs([])
    }
}




export const updateCoolections = async (projectId, setCollections) => {
    try {
        const res = await fetch(`${config.apiURL}/get-collections`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId || 'solanacryptic'
            })
        })
        const resJson = await res.json()
        setCollections(resJson.data.collections)
        // setCollections([
        //     {
        //         projectId: 'solanacryptic',
        //         collectionId: 'rusted-satchel',
        //         description: `This simple satchel may not contain the rarest treasures, but it's a great way to start your journey as a treasure hunter.`,
        //         rewards: [
        //             {type: 'SOL'},
        //             {type: 'SPL-TOKEN', symbol: 'BUFF'},
        //             {type: 'SPL-TOKEN', symbol: 'CRYP'},
        //             {type: 'SPL-TOKEN', symbol: 'DUST'},
        //             {type: 'NFT', symbol: 'Degods'},
        //             {type: 'NFT', symbol: 'BuffBunny'},
        //             {type: 'NFT', symbol: 'CATastrophic'}
        //         ],
        //         openingOptions: {},
        //         data: {}
        //     },
        //     {
        //         projectId: 'solanacryptic',
        //         collectionId: 'solana-chest',
        //         rewards: [
        //             {type: 'SOL'},
        //             {type: 'SPL-TOKEN', symbol: 'BUFF'},
        //             {type: 'SPL-TOKEN', symbol: 'CRYP'},
        //             {type: 'SPL-TOKEN', symbol: 'DUST'},
        //             {type: 'SPL-TOKEN', symbol: 'BRAF'},
        //             {type: 'NFT', symbol: 'Degods'},
        //             {type: 'NFT', symbol: 'BuffBunny'},
        //             {type: 'NFT', symbol: 'Yolo Foxes'},
        //             {type: 'NFT', symbol: 'y00ts'},
        //             {type: 'NFT', symbol: 'CATastrophic'}
        //         ],
        //         openingOptions: {},
        //         data: {}
        //     }
        // ])
    } catch (err) {
        console.log(err)
        setCollections([])
    }
}

export const sleep = (time) => {
    return new Promise(resolve => setTimeout(resolve, time))
}

export function nFormatter(num) {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "B" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    console.log(num)
    var item = lookup.slice().reverse().find(function (item) {
        return num >= item.value;
    });
    if(num < 1) return num
    return item ? ((num) / item.value) + item.symbol : "0";
}
