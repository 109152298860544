import { useWallet } from '@solana/wallet-adapter-react'
import { WalletDisconnectButton, WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import React from 'react'

export default function ConnectButton(props) {
    const { publicKey } = useWallet()
    if (!publicKey) {
        return (
            <WalletMultiButton className='utility__connect-disconnect-btn'></WalletMultiButton>
        )
    } else {
        return (
            <WalletDisconnectButton className='utility__connect-disconnect-btn' />
        )
    }

}
