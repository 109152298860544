import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'
import { AlphaWalletAdapter, BackpackWalletAdapter, GlowWalletAdapter, PhantomWalletAdapter, SlopeWalletAdapter, SolflareWalletAdapter, SolletWalletAdapter, TorusWalletAdapter } from '@solana/wallet-adapter-wallets'
import React from 'react'
import config from '../../config.js'
require('@solana/wallet-adapter-react-ui/styles.css')
export default function WalletConnectContext({ children }) {
    const endpoint = config.rpcURL
    const wallets = [
        new PhantomWalletAdapter(),
        new SolflareWalletAdapter(),
        new BackpackWalletAdapter(),
        new SlopeWalletAdapter(),
        new TorusWalletAdapter(),
        new SolletWalletAdapter(),
        new AlphaWalletAdapter(),
        new GlowWalletAdapter()
    ]
    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    )
}
