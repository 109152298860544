import { createAssociatedTokenAccountInstruction, createBurnInstruction, createCloseAccountInstruction, createTransferInstruction, getAssociatedTokenAddress } from "@solana/spl-token";
import { LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import { enqueueSnackbar } from "notistack";
import config from "../config";
import { nFormatter } from "./utility";

export const takeLoan = async (activeNFT, publicKey, connection, signTransaction, setDisabledLoanBtn, refresh, project, loanAmount) => {
    try {
        setDisabledLoanBtn(true)
        let tx = new Transaction()

        tx = await addTransferNftToTx(tx, activeNFT.mintAddress, publicKey, new PublicKey(project.loanWallet.publicKey), connection)

        if (!tx) {
            setDisabledLoanBtn(false)
            return
        }
        let signature;
        if (tx && tx.instructions && tx.instructions.length) {
            const blockHash = await connection.getLatestBlockhash();
            tx.feePayer = publicKey;
            tx.recentBlockhash = blockHash.blockhash;
            const signed = await signTransaction(tx);
            signature = await connection.sendRawTransaction(signed.serialize());
            await connection.confirmTransaction({
                blockhash: blockHash.blockhash,
                lastValidBlockHeight: blockHash.lastValidBlockHeight,
                signature
            });
        }
        async function sendReq() {
            const res = await fetch(`${config.apiURL}/take-loan`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    signature: signature,
                    publicKey: publicKey,
                    mintAddress: activeNFT.mintAddress,
                    loanAmount: loanAmount
                })
            })
            const resJson = await res.json()
            if (resJson.message === 'Transaction not found') {
                let newReq = await sendReq()
                return newReq
            } else {
                return resJson
            }
        }
        const resJson = await sendReq()
        if (!resJson.status) {
            setDisabledLoanBtn(false)
            return enqueueSnackbar({ message: resJson.message, variant: 'error' })
        }
        else {
            refresh()
            setDisabledLoanBtn(false)
            return
        }

    } catch (err) {
        console.log(err)
        setDisabledLoanBtn(false)
        enqueueSnackbar({ message: err.message || 'An unknown error occured', variant: 'error' })
    }
}

export const repayLoan = async (activeNFT, publicKey, connection, signTransaction, setDisabledLoanBtn, refresh, project) => {
    try {
        setDisabledLoanBtn(true)
        let tx = new Transaction()
        const loanStatus = activeNFT.loanStatus
        const amount = +(((100 + loanStatus.loanOption.returnPercent) / 100 * loanStatus.loanOption.loanAmount).toFixed(9))
        // const amountToLamports = parseFloat((amount * (loanStatus.loanOption.data.decimals || LAMPORTS_PER_SOL)).toFixed(10))
        console.log(amount, loanStatus)
        tx = await addTransferSplTokenToTx(connection, tx, loanStatus.loanOption.data.tokenAddress, publicKey, new PublicKey(project.loanWallet.publicKey), { symbol: loanStatus.loanOption.data.tokenSymbol, amount: amount, decimals: loanStatus.loanOption.data.decimals })

        if (!tx) {
            setDisabledLoanBtn(false)
            return
        }
        let signature;
        if (tx && tx.instructions && tx.instructions.length) {
            const blockHash = await connection.getLatestBlockhash();
            tx.feePayer = publicKey;
            tx.recentBlockhash = blockHash.blockhash;
            const signed = await signTransaction(tx);
            signature = await connection.sendRawTransaction(signed.serialize());
            await connection.confirmTransaction({
                blockhash: blockHash.blockhash,
                lastValidBlockHeight: blockHash.lastValidBlockHeight,
                signature
            });
        }
        async function sendReq() {
            const res = await fetch(`${config.apiURL}/repay-loan`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    signature: signature,
                    publicKey: publicKey,
                    mintAddress: activeNFT.mintAddress,
                })
            })
            const resJson = await res.json()
            if (resJson.message === 'Transaction not found') {
                let newReq = await sendReq()
                return newReq
            } else {
                return resJson
            }
        }
        const resJson = await sendReq()
        if (!resJson.status) {
            setDisabledLoanBtn(false)
            return enqueueSnackbar({ message: resJson.message, variant: 'error' })
        }
        else {
            refresh()
            setDisabledLoanBtn(false)
            return
        }

    } catch (err) {
        console.log(err)
        setDisabledLoanBtn(false)
        enqueueSnackbar({ message: err.message || 'An unknown error occured', variant: 'error' })
    }
}

const addTransferNftToTx = async (tx, mintAddress, sourceWallet, destinationWallet, connection) => {
    const mint = new PublicKey(mintAddress)
    let sourceAccount = await getAssociatedTokenAddress(
        mint,
        sourceWallet
    )
    const destinationAccount = await getAssociatedTokenAddress(
        mint,
        destinationWallet
    )
    if (!(await connection.getAccountInfo(sourceWallet))) {
        tx.add(createAssociatedTokenAccountInstruction(
            sourceWallet,
            destinationAccount,
            destinationWallet,
            mint
        ))
    }
    tx.add(createTransferInstruction(sourceAccount, destinationAccount, sourceWallet, 1))
    tx.add(createCloseAccountInstruction(sourceAccount, sourceWallet, sourceWallet))
    return tx

}


const addBurnNftToTx = async (tx, mintAddress, sourceWallet) => {
    const mint = new PublicKey(mintAddress)
    let sourceAccount = await getAssociatedTokenAddress(
        mint,
        sourceWallet
    );
    tx.add(createBurnInstruction(sourceAccount, mint, sourceWallet, 1))
    tx.add(createCloseAccountInstruction(sourceAccount, sourceWallet, sourceWallet))
    return tx

}

const addTransferSplTokenToTx = async (connection, tx, mintAddress, sourceWallet, destinationWallet, tokenInfo) => {
    const mint = new PublicKey(mintAddress)

    let sourceAccount = await getAssociatedTokenAddress(
        mint,
        sourceWallet
    );

    const sourceAccountData = (await connection.getParsedAccountInfo(sourceAccount))
    if (!sourceAccountData.value || Number(sourceAccountData?.value?.data?.parsed.info?.tokenAmount?.amount) < tokenInfo.amount * tokenInfo.decimals) {
        enqueueSnackbar({ message: `You need to have atleast ${nFormatter(tokenInfo.amount)} ${tokenInfo.symbol} to open the NFT`, variant: 'error' })
        return
    }

    const destinationAccount = await getAssociatedTokenAddress(
        mint,
        destinationWallet
    );
    if (!(await connection.getAccountInfo(destinationAccount))) {
        tx.add(createAssociatedTokenAccountInstruction(
            sourceWallet,
            destinationAccount,
            destinationWallet,
            mint
        ))
    }
    tx.add(createTransferInstruction(sourceAccount, destinationAccount, sourceWallet, Math.floor(tokenInfo.amount * tokenInfo.decimals)))
    return tx

}

