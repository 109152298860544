import React, { useContext, useEffect } from 'react'
import { MainContext } from '../App'
import ActiveNFT from '../components/mainPage/ActiveNFT'
import Preloader from '../components/utility/Preloader.js'
import './../css/NFTs.css'
export default function Main() {
  const { project, NFTs, activeNFTState, collections } = useContext(MainContext)
  const [activeNFT, setActiveNFT] = activeNFTState
  if (!project || !NFTs || !collections) {
    return (<Preloader />)
  } else if (!NFTs.length) {
    return (
      <div className='utility__center-text'>
        You don't own any NFT to use as collateral!
      </div>
    )
  } else {
    if (!activeNFT) {
      return (
        <div className='NFTs__wrapper'>
          {NFTs.filter(z => !z.loanStatus?.status).map((x, i) => {
            return (
              <div key={i} className='NFTs__container' onClick={() => setActiveNFT(x)}>
                <div className='NFTs__NFT-img-container'><img alt={x.name} className='NFTs__NFT-img' src={x.image} /></div>
                <div className='NFTs__NFT-name'>{x.name}</div>
              </div>
            )
          })}

          {NFTs.filter(z => z.loanStatus?.status).map((x, i) => {
            return (
              <div key={i} className='NFTs__loaned-container' onClick={() => setActiveNFT(x)}>
                <div className='NFTs__NFT-img-container'><img alt={x.name} className='NFTs__NFT-img' src={x.image} /></div>
                <div className='NFTs__NFT-name'>{x.name}</div>
              </div>
            )
          })}

        </div>
      )
    } else {
      return (
        <div>
          <ActiveNFT />
        </div>
      )
    }
  }
}