import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/utility.css'
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import WalletConnectContext from './components/utility/WalletConnectContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <WalletConnectContext>
      <Routes>
        <Route path='/dashboard' element={<App />} />
        <Route path='/:id' element={<App />} />
        <Route path='/*' element={<App />} />
      </Routes>
    </WalletConnectContext >
  </BrowserRouter>
);

