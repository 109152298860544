import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { MainContext } from '../../App'
import { repayLoan, takeLoan } from '../../javascript/NFT'
import { nFormatter } from '../../javascript/utility'
import Preloader from '../utility/Preloader'
import prettyMilliseconds from 'pretty-ms'

export default function ActiveNFT() {
    const { activeNFTState, collections, refresh, NFTs, project } = useContext(MainContext)
    const [activeNFT, setActiveNFT] = activeNFTState
    const [disabledLoanBtn, setDisabledLoanBtn] = useState(false)
    const [loanAmount, setLoanAmount] = useState()
    const [loanOption, setLoanOption] = useState()
    const { publicKey, signTransaction } = useWallet()
    const { connection } = useConnection()
    useEffect(() => {
        if (activeNFT) {
            setActiveNFT(NFTs.find(z => z.mintAddress === activeNFT.mintAddress))
        }
    }, [NFTs])
    useEffect(() => {
        if (activeNFT) {
            const collection = collections.find(x => x.collectionId === activeNFT.collectionId)
            if (collection && collection.loanPriceOptions) setLoanOption(collection.loanPriceOptions[0])
        }
    }, [activeNFT])
    return (
        <div className='NFTs__active-NFT__wrapper'>
            <div className='NFTs__active-NFT__container' style={{ gap: '0.2rem' }}>
                <button className='utility__button-cyan-animated' onClick={() => setActiveNFT()} style={{ marginBottom: '1rem' }}><span>Back to My NFTs</span><i></i></button>
                <div className='NFTs__NFT-img-container'><img style={{ width: '150px', maxWidth: '80vw' }} alt={activeNFT.name} className='NFTs__NFT-img' src={activeNFT.image} /></div>
                <div className='NFTs__NFT-name'>{activeNFT.name}</div>
                <OpeningAvailableTimer deadline={activeNFT?.loanStatus?.loanedAt + activeNFT?.loanStatus?.loanOption?.timeToPay || Date.now() - 2} />
            </div>

            <div className='NFTs__active-NFT__container' style={{ width: '370px', maxWidth: '100%' }}>
                {collections.find(x => x.collectionId === activeNFT.collectionId)?.description ? (<div className='NFTs__active-NFT__info-container' >
                    {collections.find(x => x.collectionId === activeNFT.collectionId)?.description}
                </div>) : <></>}
                {loanOption && (
                    <>
                        <div className='NFTs__active-NFT__info-container' style={{ textAlign: 'center' }}>
                            <div className='NFTs__active-NFT__info-title'>Maximum Time to Pay</div>
                            {prettyMilliseconds(loanOption.timeToPay, { verbose: true })}
                        </div>
                        <div className='NFTs__active-NFT__info-container' style={{ textAlign: 'center' }}>
                            <div className='NFTs__active-NFT__info-title'>Interest Rate</div>
                            {loanOption.returnPercent}% per month
                        </div>
                        {!activeNFT?.loanStatus?.status ? (<>
                            <form onSubmit={(event) => {
                                event.preventDefault()
                            }} className='utility__forms__grey-form' style={{ padding: '0px' }}>
                                <div className='field required' style={{ margin: '0 0 0.5em 0' }}>
                                    <div className='label font-share-tech' style={{ padding: '0px' }}>Loan Amount</div>
                                    <input required className='text-input' value={loanAmount} onChange={(event) => {
                                        if (Number(event.target.value) <= 0) {
                                            setLoanAmount(0)
                                        } else if (Number(event.target.value) <= loanOption.maxAmount) {
                                            setLoanAmount(Math.floor(Number(event.target.value)))
                                        } else {
                                            setLoanAmount(loanOption.maxAmount)
                                        }
                                    }} type={'number'} />
                                </div>
                            </form>
                            <button disabled={disabledLoanBtn} onClick={() => takeLoan(activeNFT, publicKey, connection, signTransaction, setDisabledLoanBtn, refresh, project, loanAmount)} className='utility__button-cyan-animated'><span>Loan {loanAmount} {loanOption.data.tokenSymbol}</span><i></i></button>
                        </>
                        ) : (
                            <>
                                <button disabled={activeNFT?.openingStatus?.nextOpeningTime > Date.now() || disabledLoanBtn ? true : false} onClick={() => repayLoan(activeNFT, publicKey, connection, signTransaction, setDisabledLoanBtn, refresh, project)} className='utility__button-cyan-animated'><span>Repay {+(((100+activeNFT.loanStatus.loanOption.returnPercent)/100*activeNFT.loanStatus.loanOption.loanAmount).toFixed(9))} {activeNFT.loanStatus.loanOption.data.tokenSymbol}</span><i></i></button>
                            </>
                        )}
                    </>
                )}

            </div>
        </div>
    )
}



const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

export const OpeningAvailableTimer = ({ deadline = new Date().toString() }) => {
    const parsedDeadline = useMemo(() => new Date(deadline), [deadline]);
    const [time, setTime] = useState(parsedDeadline - Date.now());

    useEffect(() => {
        const interval = setInterval(
            () => setTime(parsedDeadline - Date.now()),
            1000,
        );
        return () => clearInterval(interval);
    }, [parsedDeadline]);

    if (time >= 0) {
        return (
            <div className="NFTs__active-NFT__info-container" style={{ background: '#0c1c2b', borderColor: '#009bff' }}>
                <div className='NFTs__active-NFT__info-title'>REMAINING TIME TO REPAY</div>
                <div style={{ display: 'flex', justifyContent: 'center', flexFlow: 'row wrap', gap: '0.5rem' }}>
                    {Object.entries({
                        'D': time / DAY,
                        'H': (time / HOUR) % 24,
                        'M': (time / MINUTE) % 60,
                        'S': (time / SECOND) % 60,
                    }).map(([label, value]) => (
                        <div key={label} className="raffle-tool__raffle-timer-columns font-signika">
                            <div className="raffle-timer-time font-signika">
                                <div>{`${Math.floor(value)}`.padStart(2, "0")}{label}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    } else {
        return (
            <>
            </>

        )
    }
};

