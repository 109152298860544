import { useWallet } from '@solana/wallet-adapter-react'
import { closeSnackbar, SnackbarProvider } from 'notistack'
import React, { createContext, useEffect, useState } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import Navbar from './components/utility/Navbar'
import { updateCoolections, updateKeysOwned, updateNFTsOwned, updateProject } from './javascript/utility'
import Dashboard from './pages/Dashboard'
import Main from './pages/Main'
import CloseButton from '@mui/icons-material/Close';
import { Button } from '@mui/material'
export default function App() {
    const [project, setProject] = useState({})
    const [NFTs, setNFTs] = useState()
    const [collections, setCollections] = useState()

    const [activeNFT, setActiveNFT] = useState('')
    const params = useParams()
    const { publicKey } = useWallet()
    const refresh = async () => {
        updateProject(params.id, setProject)
        updateNFTsOwned(publicKey, params.id, setNFTs)
        updateCoolections(params.id, setCollections)
    }

    useEffect(() => {
        const updateInfo = async () => {
            updateProject(params.id, setProject)
            updateNFTsOwned(publicKey, params.id, setNFTs)
            updateCoolections(params.id, setCollections)
        }
        updateInfo()
    }, [publicKey, params])


    return (
        <MainContext.Provider value={{
            project: project,
            NFTs: NFTs,
            collections: collections,
            activeNFTState: [activeNFT, setActiveNFT],
            refresh: refresh
        }}>
            <SnackbarProvider
                autoHideDuration={5000}
                maxSnack={5}
                // action={key => <Button style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', padding: '0.1rem', minWidth: 'fit-content'}} onClick={() => closeSnackbar(key)}><CloseButton id={key} /></Button>}
                className={'utility__snack-bar'}
            />
            <Navbar />
            <Routes>
                <Route path='/' element={<Main />} />
                <Route path='/dashboard' element={<Dashboard />} />
            </Routes>
        </MainContext.Provider>
    )
}

export const MainContext = createContext({
    project: undefined,
    NFTs: undefined,
    collections: undefined,
    refresh: undefined,
    activeNFTState: {}
})